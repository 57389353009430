import React, {useState, useRef, useEffect, useCallback} from 'react';
// @ts-ignore
import {CSSTransitionGroup} from 'react-transition-group'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
// @ts-ignore
import Loader from 'react-loader-spinner'
import {Item} from './item'
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {fetchPizzas, lastPizza, setFind} from "../../redux/actions/pizzas";
import {addPizza, fetchSetOrder, minusCartItem, plusCartItem, removeCartItem} from '../../redux/actions/cart';


import Quagga from '@ericblade/quagga2';
import Scanner from './Scanner';
import Result from './Result';


import {CartItemPizza} from "../../types";
import {Field, Form, Formik} from "formik";
import {useHistory} from "react-router-dom";


const loader = <Loader className="m-auto"
                       type="TailSpin"
                       color="#007bff"
                       height={100}
                       width={100}
                       interval={3000} //3 secs

/>

export const Catalog: React.FC = () => {

    const {isLoaded, items, find} = useSelector(({pizzas}: RootStateOrAny) => (pizzas))

    const cart = useSelector(({cart}: RootStateOrAny) => (cart))
    let history = useHistory();

    const {user} = useSelector(({cart}: RootStateOrAny) => cart);

    // console.log(cart)
    const addedPizzas = Object.keys(cart.items).map((key) => {
        return {id: cart.items[key].items[0].id, count: cart.items[key].items.length};
    });


    const dispatch = useDispatch()
    useEffect(() => {
        // code to run on component mount
        dispatch(fetchPizzas(() => {
            history.push("/auth/signin")
        },() => {
            history.push("/auth/signin")
        }))
        dispatch(setFind(''))

    }, [])

    const handleAddToCart = (objPizza: CartItemPizza) => {
        dispatch(addPizza(objPizza))
    }
    const handleLastItem = () => {
        dispatch(lastPizza())
    }

    const ItemsArrayLenght = items.length - 1


    const [scanning, setScanning] = useState(false); // toggleable state for "should render scanner"
    const [cameras, setCameras] = useState([]); // array of available cameras, as returned by Quagga.CameraAccess.enumerateVideoDevices()
    const [cameraId, setCameraId] = useState(null); // id of the active camera device
    const [cameraError, setCameraError] = useState(null); // error message from failing to access the camera
    const [results, setResults] = useState([]); // list of scanned results
    const [torchOn, setTorch] = useState(false); // toggleable state for "should torch be on"
    const scannerRef = useRef(null); // reference to the scanner element in the DOM

    // at start, we need to get a list of the available cameras.  We can do that with Quagga.CameraAccess.enumerateVideoDevices.
    // HOWEVER, Android will not allow enumeration to occur unless the user has granted camera permissions to the app/page.
    // AS WELL, Android will not ask for permission until you actually try to USE the camera, just enumerating the devices is not enough to trigger the permission prompt.
    // THEREFORE, if we're going to be running in Android, we need to first call Quagga.CameraAccess.request() to trigger the permission prompt.
    // AND THEN, we need to call Quagga.CameraAccess.release() to release the camera so that it can be used by the scanner.
    // AND FINALLY, we can call Quagga.CameraAccess.enumerateVideoDevices() to get the list of cameras.

    // Normally, I would place this in an application level "initialization" event, but for this demo, I'm just going to put it in a useEffect() hook in the App component.


    const setResultsandExit = (result: any) => {

        // @ts-ignore
        setResults([result.substring(0, 10)])
        dispatch(setFind(result.substring(0, 10)))
        setScanning(!scanning)
    }

    // @ts-ignore
    useEffect(() => {

        const enableCamera = async () => {
            await Quagga.CameraAccess.request(null, {});
        };
        const disableCamera = async () => {
            await Quagga.CameraAccess.release();
        };
        const enumerateCameras = async () => {
            const cameras = await Quagga.CameraAccess.enumerateVideoDevices();
            console.log('Cameras Detected: ', cameras);
            return cameras;
        };

        enableCamera()
            .then(disableCamera)
            .then(enumerateCameras)
            // @ts-ignore
            .then((cameras) => setCameras(cameras))
            .then(() =>  Quagga.CameraAccess.enableTorch()) // disable torch at start, in case it was enabled before and we hot-reloaded
            .catch((err) => setCameraError(err));
        return () => disableCamera();
    }, []);

    // provide a function to toggle the torch/flashlight
    const onTorchClick = useCallback(() => {
        const torch = !torchOn;
        setTorch(torch);
        if (torch === true) {
            Quagga.CameraAccess.enableTorch();
        }
        if (torch === false){
            Quagga.CameraAccess.disableTorch();
        }
    }, [ setTorch]);


    const handleChange = function (event: any) {
        // @ts-ignore
        setResults([event.target.value]);
        dispatch(setFind(event.target.value))
    }


    const onPlusItem = (id: number) => {
        dispatch(plusCartItem(id));
    };

    const onMinusItem = (id: number) => {
        dispatch(minusCartItem(id));
    };
    const onRemoveItem = (id: number) => {

        dispatch(removeCartItem(id));

    };


    // @ts-ignore

    return (
        <CSSTransitionGroup
            transitionName="Transition"
            transitionAppear={true}
            transitionAppearTimeout={500}
            transitionEnter={false}
            transitionLeave={false}>

            {user.email ?
                <div>
                    <div ref={scannerRef} style={{position: 'absolute', marginTop: '120px'}}>

                        {<video style={{
                            width: window.innerWidth,
                            height: '290px',
                            display: scanning ? 'block' : 'none',
                            zIndex: 111
                        }}/>}
                        <canvas className="drawingBuffer" style={{
                            position: 'absolute',
                            top: '0px',
                            // left: '0px',
                            // height: '100%',
                            // width: '100%',
                            // border: '3px solid green',
                            width: window.innerWidth,
                            height: '290px',
                            display: scanning ? 'block' : 'none',
                            zIndex: 111

                        }}/>
                        {scanning ? <Scanner scannerRef={scannerRef} cameraId={cameraId}
                                             onDetected={(result) => setResultsandExit(result)}/> : null}
                    </div>

                    <div className='container mt-12'>


                        {cameraError ? <p>ERROR INITIALIZING CAMERA ${JSON.stringify(cameraError)} -- DO YOU HAVE
                            PERMISSION?</p> : null}
                        {cameras.length === 0 ?
                            <p>Enumerating Cameras, browser may be prompting for permissions beforehand</p> :
                            <form style={{display: 'block'}}>
                                <select onChange={(event) => setCameraId(event.target.value)}>
                                    {cameras.map((camera) => (
                                        <option key={camera.deviceId} value={camera.deviceId}>
                                            {camera.label || camera.deviceId}
                                        </option>
                                    ))}
                                </select>
                            </form>
                        }
                        <div className="form-group d-flex flex-column input-group-lg">

                            <input name="text" type='search' aria-describedby="inputGroup-sizing-lg"
                                   className="form-control" placeholder="12345" value={find}
                                   onChange={(value: any) => handleChange(value)}/>

                        </div>

                        <div style={{marginBottom: '30px', zIndex: '300'}}>
                            <button type="button" className="btn btn-primary btn-lg btn-block"
                                    style={{textAlign: 'center'}}
                                    onClick={() => setScanning(!scanning)}>{scanning ? 'Остановить' : 'Сканировать'}</button>
                        </div>
                        <div>
                            {scanning ?
                            <button style={{display: 'block',position: 'relative', zIndex: "999999"}} type="button" className="btn button btn-primary btn-lg"  onClick={onTorchClick}>{torchOn ? 'Включить вспышку' : 'Выключить вспышку'}</button>: null}



                        </div>


                        <div className={isLoaded ? 'row invisible opacity-0-loader' : 'row'}>
                            {
                                loader
                            }
                        </div>
                        <div className={isLoaded ? 'row' : ' row invisible opacity-0-pizza'}>
                            {
                                items.map((obj: CartItemPizza, index: number, array: CartItemPizza[]) => {
                                        let countPizzas: number = 0
                                        if (addedPizzas) {
                                            addedPizzas.forEach((x: any) => {
                                                if (x.id == obj.id) {
                                                    countPizzas = x.count
                                                }

                                            })

                                        }

                                        function isANumber(str: any) {
                                            return !/\D/.test(str);
                                        }

                                        let funct = function () {
                                            if (isANumber(parseInt(find))) {


                                                if (find.length >= 6) {


                                                    return obj.code.includes(find.substring(0, 5)) && obj.design.includes(find.substring(5, 10))
                                                }


                                            }

                                            return obj.code.includes(find) || obj.name.includes(find.toUpperCase()) || obj.design.includes(find)


                                        }

                                        if (funct()) {
                                            return <Item
                                                key={index + obj.name} onAddToCart={handleAddToCart} onMinus={onMinusItem}
                                                onPlus={onPlusItem} onRemove={onRemoveItem}
                                                last={{index, handleLastItem, ItemsArrayLenght}} currency={cart.currency}
                                                data={obj} count={countPizzas}
                                            />
                                        }

                                    }
                                )
                            }
                        </div>


                    </div>
                </div>
                : null

            }
        </CSSTransitionGroup>

    );
}


